* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  //  top: 0;
}



@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Playfair+Display&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Libre+Baskerville&family=Playfair+Display&family=Roboto:wght@100&family=Vidaloka&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Libre+Baskerville&family=Playfair+Display&family=Roboto:wght@100&family=Tenor+Sans&family=Vidaloka&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Libre+Baskerville&family=Playfair+Display&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100&family=Tenor+Sans&family=Vidaloka&display=swap');
body {
  font-family: 'Cinzel', serif;
  font-family: 'Libre Baskerville', serif;
  font-family: 'Playfair Display', serif;
  font-family: 'Raleway', sans-serif;
  /*font-family: 'Roboto', sans-serif;
  font-family: 'Tenor Sans', sans-serif;
  font-family: 'Vidaloka', serif;*/
}

.icons {
  font-size: 1.2rem;
}