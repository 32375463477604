.fab {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    background-color: $blueColor;
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    color: white;
    font-size: 30px;
    border-width: 0px;
}


.star {
    color: rgb(221, 202, 1);
}

.proprietario:hover {
    color: $blueColor;
    transition: 0.5s;

}



/*sito*/
.hbanner { 
    background-image: linear-gradient(rgba(255, 255, 255, 0.109), rgba(255, 255, 255, 0.439)), url('../../img/esterno.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    //opacity: 1;
    //position: relative;
 //   height: 40rem;
    //color: rgba(28, 26, 26, 0.685);
}
.blank-space { height: 13rem;}
.active { color: white !important; }
.active:hover { color: $greyColor;}
.link-dark:hover {
    box-shadow: 3px 10px 96px -7px rgba(0,0,0,0.69);
    -webkit-box-shadow: 3px 10px 96px -7px rgba(0,0,0,0.69);
    -moz-box-shadow: 3px 10px 96px -7px rgba(0,0,0,0.69);
    font-size: 1.2rem;
    transition: 0.5s;
}
.link-dark {
    color: rgb(215, 215, 215) !important;
}

.lead {
    color: white;
}


.card-ricerca {
    border: none;
    //box-shadow: 10px 10px 5px #00000031;
    box-shadow: 13px 10px 22px 2px rgba(0,0,0,0.4);
-webkit-box-shadow: 13px 10px 22px 2px rgba(0,0,0,0.4);
-moz-box-shadow: 13px 10px 22px 2px rgba(0,0,0,0.4);

}

.price {
    background-color: $greyColor;
    color: white;
    height: 1.5rem;
    width: 100px;
    margin-right: -16px;
    border-radius: 10px 0 0 10px;


}


.card-annuncio {
    border: none;
    cursor: pointer;
}

.card-annuncio > .card-img-top {
    height: 200px;
    object-fit: cover;
}

.parallax-one {
    padding-top: 200px; padding-bottom: 200px; overflow: hidden; position: relative; width: 100%; background-image: url('../../img/salotto.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: top center;
}

.container-servizi .row .col {
    border: white;
    color: white;
    background-color: $greyColor;
    width: 100%;
    height: 5rem;
}

.container-servizi .row .col .p-2:hover {
    box-shadow: 10px 10px 5px #00000031;
    transition: 0.5s;
}

.servizio {
    background-color: $greyColor;
    color: $whiteColor;
    //min-height: 5rem;
}

.servizio:hover {
    transition: 0.3s;
    box-shadow: 10px 10px 5px #00000031;
}

.card-agente {
    border: none;
}

.card-agente > .card-img-top {
    max-height: 270px;
    object-fit: cover;
}

.img-agente {
    max-width: 270px;
}

.pic {
    max-height: 270px;
    object-fit: cover;
}

.header-bg {
    background-image: linear-gradient(rgba(255, 255, 255, 0.396), rgba(255, 255, 255, 0.439)), url('../../img/natalesalotto.jpg');
}

.parallax-ricerca {
    padding-top: 100px; padding-bottom: 100px; overflow: hidden; position: relative; width: 100%; background-image: url('../../img/natalesalotto.jpg'); background-attachment: fixed; background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-repeat: no-repeat; background-position: top center;
}

.form-archive {
    background-color: red;
}

.cookie-banner {
    bottom: 0;
    top: unset!important;
}


.red-text { color: $whiteColor; box-shadow: -1px 1px 38px -4px #093d0986;
    -webkit-box-shadow: -1px 1px 38px -4px #1374139f;
    -moz-box-shadow: -1px 1px 38px -4px #137413a8;
background-color: #0000001c;}