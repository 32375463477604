.grey {
    background-color: $greyColor;
    color: white;
}
.green {
    background-color: white;
    color: $greenColor;
}

.white {
    background-color: $whiteColor;
}

.text-bianco {
    color: $whiteColor;
}

.text-verde {
    color: $greenColor;
}